import React from 'react'
import './style.css'
import { NavLink } from 'react-router-dom'
import PostJob from '../../Pages/Dashboard'

const Sidebar = () => {
  return (
    <>
    <div className="sidebarItems">
        <div className="sideBarItem">Posted Jobs</div>
            <NavLink to='/dashboard/postJob' className="sideBarItem" activeClassName="active">New Job</NavLink>
    </div>
    </>
  )
}

export default Sidebar