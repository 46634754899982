import NGLogo from '../../Assets/NexGen_Logo.png';
import Web from '../../Assets/Web.png'
import Android from '../../Assets/Android-Tv.png'
import Database from '../../Assets/Database.png'
import FireTv from '../../Assets/FireTv.png'
import Hr from '../../Assets/Hr.png'
import Ios from '../../Assets/Ios.png'
import Sales from '../../Assets/Sales.png'
import { Button } from '@mui/material';



let data;
export default  data = [
    {
        "id": 1,
        "Job": "Frontend Developer",
        "Key Skills": "Docker,React,Node,Express",
        "Logo": Web,
        "Location": "Delhi NCR",
        "Job Description": "<b>Postition Overview:</b>\n We are seeking a dynamic and motivated BDR / Sales Executive to join our growing team. This role is designed for an ambitious individual who will initially work as a Business Development Representative (BDR), with the potential to grow into an Account Executive role upon demonstrated success. As part of the sales team, you will be responsible for generating and qualifying leads, building relationships with potential clients, and helping drive revenue growth by selling innovative OTT solutions to the sports market (focusing on teams and leagues) and  the media entertainment industry.\nKey Responsibilities:\nAs a BDR:\nIdentify and qualify new business opportunities within the sports and media entertainment sectors.\nConduct research to understand target prospects' needs and pain points, and how ViewLift solutions can add value.\nGenerate and engage leads through cold calling, email outreach, and social media.\nSchedule meetings and product demos for senior sales team members.\nCollaborate with the marketing and sales teams to refine outreach strategies and improve lead generation efforts.\nBuild and maintain a robust pipeline of qualified leads using Hubspot.",
        "Key Qualifications": [
            "HTML 5",
            "CSS 3",
            "JavaScript",
            "React"
        ],
        "JobPosted": "14/10/2024",
        "Experience": "1-3 Years"
    },
    {
        "id": 2,
        "Job": "Backend Developer",
        "Key Skills": "Docker,React,Node,Express",
        "Logo": Android,
        "Location": "Delhi NCR",
        "Job Description": "<b>Postition Overview:</b>\n We are seeking a dynamic and motivated BDR / Sales Executive to join our growing team. This role is designed for an ambitious individual who will initially work as a Business Development Representative (BDR), with the potential to grow into an Account Executive role upon demonstrated success. As part of the sales team, you will be responsible for generating and qualifying leads, building relationships with potential clients, and helping drive revenue growth by selling innovative OTT solutions to the sports market (focusing on teams and leagues) and  the media entertainment industry.\nKey Responsibilities:\nAs a BDR:\nIdentify and qualify new business opportunities within the sports and media entertainment sectors.\nConduct research to understand target prospects' needs and pain points, and how ViewLift solutions can add value.\nGenerate and engage leads through cold calling, email outreach, and social media.\nSchedule meetings and product demos for senior sales team members.\nCollaborate with the marketing and sales teams to refine outreach strategies and improve lead generation efforts.\nBuild and maintain a robust pipeline of qualified leads using Hubspot.",
        "Key Qualifications": [
            "HTML 5",
            "CSS 3",
            "JavaScript",
            "React"
        ],
        "JobPosted": "14/10/2024",
        "Experience": "1-3 Years"
    },
    {
        "id": 3,
        "Job": "DevOps Developer",
        "Key Skills": "Docker,React,Node,Express",
        "Logo": Database,
        "Location": "Delhi NCR",
        "Job Description": "<b>Postition Overview:</b>\n We are seeking a dynamic and motivated BDR / Sales Executive to join our growing team. This role is designed for an ambitious individual who will initially work as a Business Development Representative (BDR), with the potential to grow into an Account Executive role upon demonstrated success. As part of the sales team, you will be responsible for generating and qualifying leads, building relationships with potential clients, and helping drive revenue growth by selling innovative OTT solutions to the sports market (focusing on teams and leagues) and  the media entertainment industry.\nKey Responsibilities:\nAs a BDR:\nIdentify and qualify new business opportunities within the sports and media entertainment sectors.\nConduct research to understand target prospects' needs and pain points, and how ViewLift solutions can add value.\nGenerate and engage leads through cold calling, email outreach, and social media.\nSchedule meetings and product demos for senior sales team members.\nCollaborate with the marketing and sales teams to refine outreach strategies and improve lead generation efforts.\nBuild and maintain a robust pipeline of qualified leads using Hubspot.",
        "Key Qualifications": [
            "HTML 5",
            "CSS 3",
            "JavaScript",
            "React"
        ],
        "JobPosted": "14/10/2024",
        "Experience": "1-3 Years"
    },
    {
        "id": 4,
        "Job": "System Developer",
        "Key Skills": "Docker,React,Node,Express",
        "Logo": FireTv,
        "Location": "Delhi NCR",
        "Job Description": "<b>Postition Overview:</b>\n We are seeking a dynamic and motivated BDR / Sales Executive to join our growing team. This role is designed for an ambitious individual who will initially work as a Business Development Representative (BDR), with the potential to grow into an Account Executive role upon demonstrated success. As part of the sales team, you will be responsible for generating and qualifying leads, building relationships with potential clients, and helping drive revenue growth by selling innovative OTT solutions to the sports market (focusing on teams and leagues) and  the media entertainment industry.\nKey Responsibilities:\nAs a BDR:\nIdentify and qualify new business opportunities within the sports and media entertainment sectors.\nConduct research to understand target prospects' needs and pain points, and how ViewLift solutions can add value.\nGenerate and engage leads through cold calling, email outreach, and social media.\nSchedule meetings and product demos for senior sales team members.\nCollaborate with the marketing and sales teams to refine outreach strategies and improve lead generation efforts.\nBuild and maintain a robust pipeline of qualified leads using Hubspot.",
        "Key Qualifications": [
            "HTML 5",
            "CSS 3",
            "JavaScript",
            "React"
        ],
        "JobPosted": "14/10/2024",
        "Experience": "1-3 Years"
    },
    {
        "id": 5,
        "Job": "Frontend Developer",
        "Key Skills": "Docker,React,Node,Express",
        "Logo": Ios,
        "Location": "Delhi NCR",
        "Job Description": "<b>Postition Overview:</b>\n We are seeking a dynamic and motivated BDR / Sales Executive to join our growing team. This role is designed for an ambitious individual who will initially work as a Business Development Representative (BDR), with the potential to grow into an Account Executive role upon demonstrated success. As part of the sales team, you will be responsible for generating and qualifying leads, building relationships with potential clients, and helping drive revenue growth by selling innovative OTT solutions to the sports market (focusing on teams and leagues) and  the media entertainment industry.\nKey Responsibilities:\nAs a BDR:\nIdentify and qualify new business opportunities within the sports and media entertainment sectors.\nConduct research to understand target prospects' needs and pain points, and how ViewLift solutions can add value.\nGenerate and engage leads through cold calling, email outreach, and social media.\nSchedule meetings and product demos for senior sales team members.\nCollaborate with the marketing and sales teams to refine outreach strategies and improve lead generation efforts.\nBuild and maintain a robust pipeline of qualified leads using Hubspot.",
        "Key Qualifications": [
            "HTML 5",
            "CSS 3",
            "JavaScript",
            "React"
        ],
        "JobPosted": "14/10/2024",
        "Experience": "1-3 Years"
    }
]