import React, { useState } from 'react';
import './style.css';
import { FormControl } from '@mui/material';

const JobApplicationForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [resume, setResume] = useState(null);
  const [message, setMessage] = useState('');
  const [details, setDetails] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a FormData object to send to the backend
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', details)
    formData.append('resume', resume); // Attach the resume file

    // Use fetch to send the form data to the backend
    fetch("http://localhost:3000/apply", {
      method: "POST",
      body: formData, // Pass the formData directly
    })
    .then(response => response.json()) // Parse the JSON response
    .then(json => {
      console.log(json);
      setMessage('Application submitted successfully!');
    })
    .catch((error) => {
      console.error('Error:', error);
      setMessage('There was an error submitting your application.');
    });

    // Optional: You can clear the form or handle the state here
    setName('');
    setEmail('');
    setDetails('')
    setResume(null);
  };

  return (
    <div className="jobForm">
      <h1>Job Application Form</h1>
      <FormControl  onSubmit={handleSubmit}>
        <div className='column'>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className='column'>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className='column'>
          <label>Message:</label>
          <textarea
            type="text"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            required
          />
        </div>
        <div className='column'>
          <label>Resume:</label>
          <input
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={(e) => setResume(e.target.files[0])}
            required
          />
        </div>
        <button className="btn" type="submit">Apply</button>
      </FormControl>
      {message && <p>{message}</p>}
    </div>
  );
};

export default JobApplicationForm;
