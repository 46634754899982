import './App.css';
import Navbar from './Components/Navbar/index'
import JobsPage from './Pages/JobsPage'
import JobDescription from './Pages/JobDescription';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import Layout from './Components/Layout';
import PostJob from './Pages/Dashboard';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<JobsPage />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/Jobs/:JobId' element={<JobDescription />} />

          {/* Dashboard */}
          <Route path='dashboard' element={<Layout />}>
            <Route path='postJob' element={<PostJob />} />
          </Route>
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
