import React, { useState } from 'react'
import './style.css'
import { Button, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Editor } from 'primereact/editor';
import data from '../../Assets/Data/data'
import axios from 'axios';

const PostJob = () => {
    const [text, setText] = useState()
    const [formData, setFormData] = useState({
        id: 1,
        job: '',
        keySkills: '',
        location: '',
        experience: '',
        jobType: '',
        jobIcon: '',
        jobDescription: text,
        date: Date.now()
    })

    // switch(formData.jobIcon){
    //     case :
    //         return 
    // }
    function handleChange(e) {
        const { name, value } = e.target
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    function handleSubmit(e) {
        e.preventDefault()
        axios
            .post("http://localhost:3000/jobs", formData)
            .then((response) => {
                console.log(response);
            })
            .catch((e)=>{
                console.log(e, "Error")
            })
            ;
            
        console.log(data)
        setFormData({
            id: 1,
            job: '',
            keySkills: '',
            location: '',
            experience: '',
            jobType: '',
            jobIcon: '',
            jobDescription: text,
            date: Date.now()
        })
        setText('')
    }
    return (
        <>
            <div className="dashboardContainer">
                <div className="createJobContainer">
                    <h2 style={{ fontWeight: '300' }}>Post New Jobs:-</h2>
                    <div className="formInputs">
                        <div className="inputDiv">
                            <TextField id="outlined-basic" name='job' label="Job Title" variant="outlined" value={formData.job} onChange={handleChange} />
                        </div>
                        <div className="inputDiv">
                            <TextField id="outlined-basic" name='keySkills' label="Key Skills" variant="outlined" value={formData.keySkills} onChange={handleChange} />
                        </div>
                        <div className="inputDiv">
                            <TextField id="outlined-basic" name='location' label="Location" variant="outlined" value={formData.location} onChange={handleChange} />
                        </div>
                        <div className="inputDiv">
                            <TextField id="outlined-basic" name='experience' label="Experience" variant="outlined" value={formData.experience} onChange={handleChange} />
                        </div>
                        <div className="inputDiv">
                            <InputLabel id="demo-simple-select-label">Job Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.jobType}
                                label="jobType"
                                name='jobType'
                                onChange={handleChange}
                            >
                                <MenuItem value="Full Time">Full Time</MenuItem>
                                <MenuItem value="Contract" >Contract</MenuItem>
                                <MenuItem value="Part Time">Part Time</MenuItem>
                            </Select>
                        </div>
                        <div className="inputDiv">
                            <InputLabel id="demo-simple-select-label">Choose Icon</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.jobIcon}
                                label="Choose Icon"
                                name='jobIcon'
                                onChange={handleChange}
                            >
                                <MenuItem value="Full Time">Frontend Dev</MenuItem>
                                <MenuItem value="Contract" >Backend Dev</MenuItem>
                                <MenuItem value="Part Time">DevOps</MenuItem>
                                <MenuItem value="Nexgen">Nexgen</MenuItem>
                            </Select>
                        </div>
                        <div className="inputDiv2">
                            <Editor value={text} name='jobDescription' onTextChange={(e) => setText(e.htmlValue)} style={{ height: '320px' }} />
                        </div>
                    </div>
                    <Button variant='contained' size='md' sx={{ marginY: 3, textAlign: 'left', width: '80%' }} onClick={handleSubmit}>Create</Button>
                </div>
            </div>
        </>
    )
}

export default PostJob