import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import BusinessIcon from '@mui/icons-material/Business';




const ContactCard = () => {
    return (
        <>
            <Container sx={{ display: 'flex', flexDirection: 'column', marginRight: '40%', backgroundColor: '#fefefe', paddingX: '1%', paddingY: '10%', textAlign: 'left', gap: 2 }} maxWidth>
                <Typography variant='h6'>
                    Contact Us
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', gap: 1 }}>
                    <BusinessIcon />
                    <p>450 Century Pkwy Suite 250, Allen, TX 75013, USA</p>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', gap: 1 }}>
                    <EmailIcon />
                    <p>hrindia@nexgeniots.com</p>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', gap: 1 }}>
                    <CallIcon />
                    <p>+1 (469) 570-8638</p>
                </Box>
            </Container>
        </>
    )
}

export default ContactCard