import React, { useState } from 'react';
import data from '../../Assets/Data/data.js';
import { Link } from 'react-router-dom';
import './style.css';

const Index = () => {
  // State for the three filters: Job Name, Key Skills, and Location
  const [jobName, setJobName] = useState('');
  const [keySkills, setKeySkills] = useState('');
  const [location, setLocation] = useState('');

  // Filter the data based on jobName, keySkills, and location
  const filteredData = data.filter((item) => {
    return (
      (jobName ? item.Job.toLowerCase().includes(jobName.toLowerCase()) : true) &&
      (keySkills ? item['Key Skills'].toLowerCase().includes(keySkills.toLowerCase()) : true) &&
      (location ? item['Location'].toLowerCase().includes(location.toLowerCase()) : true)
    );
  });

  // Handle input changes for each filter
  const handleJobNameChange = (e) => setJobName(e.target.value);
  const handleKeySkillsChange = (e) => setKeySkills(e.target.value);
  const handleLocationChange = (e) => setLocation(e.target.value);

  return (
    <>
      <h1>Featured Jobs</h1>
      <p className='featuredJobsDesc' style={{ margin: '10px auto', marginBottom: '40px' }}>
        We’re looking for dedicated, technologists, and creative people to join us. Explore our current open positions. If you find an opportunity that aligns with your skills, we would love to hear from you.
      </p>

      {/* Filter Section */}
      <div className="filterContainer">
        <div className="filter">
          {/* <label>Job Name</label> */}
          <input
            type="text"
            name="jobName"
            value={jobName}
            onChange={handleJobNameChange}
            placeholder="Search by Job Name"
            style={{
              padding: '10px'
            }}
          />
        </div>

        <div className="filter">
          {/* <label>Key Skills</label> */}
          <input
            type="text"
            name="keySkills"
            value={keySkills}
            onChange={handleKeySkillsChange}
            placeholder="Search by Key Skills"
            style={{
              padding: '10px'
            }}
          />
        </div>

        <div className="filter">
          {/* <label>Location</label> */}
          <input
            type="text"
            name="location"
            value={location}
            onChange={handleLocationChange}
            placeholder="Search by Location"
            style={{
              padding: '10px'
            }}
          />
        </div>

        {/* <Button variant="contained" sx={{ width: '30%' }}>Search</Button> */}
      </div>

      {/* Job Listings */}
      {filteredData.length > 0 ? (
        filteredData.map((item) => (
          <Link to={`/Jobs/${item.id}`} key={item.id}>
            <div className="jobContainer">
              <img src={item.Logo} alt="NexGen Logo"/>
              <div className="jobDetails" style={{ display: 'flex', flexDirection: 'column' }}>
                <h3>{item.Job}</h3>
                <div className="jobDescription">
                  <p><b>Key Skills: </b>{item['Key Skills']}</p>
                  <p style={{ marginLeft: '10px' }}><b>Location: </b>{item['Location']}</p>
                </div>
              </div>
              <div className="dateContainer">
                <h6>{item.JobPosted}</h6>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <p>No jobs found matching your search criteria.</p>
      )}
    </>
  );
};

export default Index;
